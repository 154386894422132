<template>
  <div
    class="fullscreen text-white text-center q-pa-md flex flex-center bg-primary"
  >
    <div>
      <div style="font-size: 30vh"> 404 </div>
      <div class="text-h2" style="opacity: 0.4"> Oops. Nothing here... </div>
      <q-btn
        v-if="$route.name != 'Home'"
        class="q-mt-xl"
        color="white"
        text-color="blue"
        unelevated
        :to="{ name: 'Home' }"
        label="Go Home"
        no-caps
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Error404',
}
</script>
